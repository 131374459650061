import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import get from 'lodash/get'
import Header from '../components/Header/header'
import {previous_page, next_page} from '../components/strings'
import Footer from '../components/Footer/footer'
import "animate.css/animate.min.css";
import {blog} from '../components/strings'
import Helmet from 'react-helmet'
import AOS from 'aos';

 class BlogPage extends React.Component {
  componentDidMount(){
    AOS.init({
      duration : 500
    })
  }
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const posts = get(this, 'props.data.allMarkdownRemark.edges')
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "/blog/" : ("/blog/" + (currentPage - 1).toString())
    const nextPage = "/blog/" + (currentPage + 1).toString()

    return (
      <Layout>
        <Helmet
          title="WELL – BLOG | Read about latest Digital Marketing Achievements"
          meta={[
            { name: 'description', content: 'In our WELL – Blog you will learn more about the trending digital marketing methods and  about the best online marketing tools.' },
            {name: 'og:image', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg" },
            {name: 'og:image:type', content: "image/jpg" },
            {name: 'og:type', content: "website" },
            {name: 'og:url', content: "https://wellpromotion.ba"},
            {name: 'twitter:card', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg"},
            {name: 'twitter:image:alt', content: "Well Business Promotion Marketing Agency" },
            ]} >
        </Helmet>
          <div id="blog">
          <Header prefix_string_page="en"/>
          <div data-aos='fade-zoom-in'>
          
            <section id="p_blog" className="section pages">
            <div className="overlay_image"></div>
            <div className="overlay_color"></div>
          {/* <h2 className="blog-soon">Our blog will be available soon...</h2> */}
            <div className="container">        
       
                <h2 className="title">{blog}</h2>
                <div className="columns">

                   <div className="columns is-multiline">
                       {posts.map(({ node }) => {
                          return(  
                          <div data-aos='fade-zoom-in' className="column is-12 single-blog">             
                            <Link to={node.fields.slug} className="blog-image"><img src={node.frontmatter.image} alt={node.frontmatter.meta} />
                            <div className="overlay"></div>
                            </Link>
                              <div className="blog-content">
                              <Link to={node.fields.slug}> <h1 className="title">{node.frontmatter.title}</h1></Link>
                                  <p className="desc">{node.frontmatter.description}</p>
                            </div>  
                          </div>   
                        )  })}      
                                        
                      </div> 

                    </div>

                    <ul className="pagination">
                { !isFirst && <Link to={"/" + prevPage} rel="prev">← {previous_page}</Link> }
                { Array.from({ length: numPages }, (_, i) => (
                    <li key={`pagination-number${i + 1}`} >
                      <Link to={"/" + `/portfolio/${i === 0 ? '' : i + 1}`} style={{
                          color: i + 1 === currentPage ? '#ffffff' : '', 
                          background: i + 1 === currentPage ? '#D4AC69' : ''
                        }} >
                        {i + 1}
                      </Link>
                    </li>
                  ))
                }
                { !isLast && <Link to={"/" + nextPage} rel="next">{next_page} &nbsp;→</Link> }
            </ul>


                </div>
               
                 
</section>
        </div>
        

        </div>

          <Footer/>
      </Layout>
    )
  }
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default BlogPage

export const blogPagePageQuery = graphql`

  query blogPagePageQuery($skip: Int = 0, $limit: Int = 6) {
    allMarkdownRemark(sort: { fields: [frontmatter___sorting_date], order: ASC },
        filter: { frontmatter: { templateKey: { eq: "en_blog" } } },
         limit: $limit, skip: $skip) {
        edges {
          node {
            excerpt
            id
            fields {
              slug
            }
            frontmatter {
                title
                client
                category
                date
                sorting_date
                website
                website_url
                description
                meta
                image
            }
          }
        }
      }
  }
`
